import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider as StyletronProvider } from 'styletron-react'
import { LightTheme, BaseProvider } from 'baseui'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SnackbarProvider } from 'baseui/snackbar'
import { ToasterContainer } from 'baseui/toast'
import { Analytics } from '@vercel/analytics/react'

import { styletron } from '../styletron'

import '../styles.css'

// axios.defaults.baseURL = uri

// Create a client
const queryClient = new QueryClient()

const BaseApp = ({ Component, pageProps }) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <StyletronProvider value={styletron}>
        <BaseProvider theme={LightTheme}>
          <SnackbarProvider placement="bottom">
            <ToasterContainer placement="top">
              <Component {...pageProps} />
              <Analytics />
            </ToasterContainer>
          </SnackbarProvider>
        </BaseProvider>
      </StyletronProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

BaseApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
}

export default BaseApp
